<template>
  <div class="job-item-container">
    <div class="job-item">
      <div class="job-img-container">
        <img :src="returnImage(jobObject.jobImage)" />
      </div>
      <div class="job-details-container">
        <h3 class="job-title">{{ jobObject.jobTitle }}</h3>
        <div class="job-summary-container">
          <span class="blog-date">
            <i class="fas fa-coins"></i>
            {{ jobObject.jobSalary }}
          </span>
          <span class="blog-date">
            <i class="fas fa-map-marked-alt"></i>
            {{ jobObject.jobLocation }}
          </span>
          <span class="blog-date">
            <i class="fas fa-clock"></i>
            Posted {{ getTimestamp(jobObject.created_at) }}
          </span>
        </div>

        <!-- Job Details -->
        <div
          v-if="isDescriptionValid(jobObject.jobDescription)"
          class="job-details"
        >
          <h4 class="detail-title">Job Description</h4>
          <span>
            {{ getDescriptionPlaceholder(jobObject.jobDescription) }}</span
          >
        </div>

        <div
          v-if="isDescriptionValid(jobObject.jobResponsibility)"
          class="job-details"
        >
          <h4 class="detail-title">Job Responsibility</h4>
          <span>
            {{ getDescriptionPlaceholder(jobObject.jobResponsibility) }}</span
          >
        </div>

        <div class="job-details" v-if="isSkillSetNotEmpty">
          <h4 class="detail-title">Skills and experience</h4>
          <ul>
            <li v-for="skill in getSkillSet" :key="`skill-item-${skill.id}`">
              {{ skill.skillTitle }}
            </li>
          </ul>
        </div>

        <button class="job-button" @click="handleApplyNow(jobObject.jobLink)">
          Apply Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/utils";

export default {
  props: {
    jobObject: {
      default: () => {
        return {};
      },
      type: Object,
    },
  },
  computed: {
    getSkillSet() {
      return this.jobObject.skillSet.filter((skill) => {
        return (
          skill.skillTitle &&
          (skill.skillTitle.length !== 0 || skill.skillTitle !== "")
        );
      });
    },
    isSkillSetNotEmpty() {
      return this.getSkillSet.length !== 0;
    },
  },
  methods: {
    returnImage(imgLoc) {
      const host = API.getBaseURL();
      return imgLoc
        ? `${host}${imgLoc.url}`
        : require("@/assets/images/hiring-default.jpg");
    },
    getTimestamp(date) {
      const resultDate = Date.now() - Date.parse(date);
      const differenceInHours = Math.ceil(Math.abs(resultDate) / 36e5);
      const differenceInDays = Math.ceil(resultDate / (1000 * 60 * 60 * 24));
      const differenceInMins = Math.floor(resultDate / 1000 / 60);

      if (differenceInMins < 60) {
        return `${differenceInMins} minute${
          differenceInMins <= 1 ? "" : "s"
        } ago `;
      } else if (differenceInHours > 24) {
        return `${differenceInDays} day${
          differenceInDays === 1 ? "" : "s"
        } ago `;
      } else if (differenceInDays > 31) {
        return new Date(date).toISOString().split("T")[0];
      } else {
        return `${differenceInHours} hour${
          differenceInHours === 1 ? "" : "s"
        } ago`;
      }
    },

    getDescriptionPlaceholder(description) {
      return description.length > 150
        ? `${description.substring(0, 150)}...`
        : description;
    },

    isDescriptionValid(description) {
      return description && description.length > 5;
    },

    handleApplyNow(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style></style>
