<template>
  <!-- Show loading spinner if content is still loading -->
  <div v-if="isLoading" class="loading-section">
    <div class="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div v-else>
    <main class="portfolio blog-main" v-if="getJobPostingList.length">
    <section class="hero">
      <div class="hero-border-left"></div>

      <div class="hero-block fade-in-bck">
        <div class="hero-title fade-in-bck">
          Job Posting
          <span class="hero-title-bar fade-in-bck"></span>
        </div>

        <div class="hero-subtitle">
          Be part of our mission to make creation on the web easier and better.
          <a
            data-mixpanel="portfolio_cta_get_free_consultation"
            @click="redirectMailTo"
            class="main-portfolio-cta btn-countable btn-outline btn-outline-red"
            >LET'S TALK</a
          >
        </div>
      </div>

      <div class="hero-white-box-bottom"></div>
    </section>

    <div class="jobs-list-container">
      <JobItem
        v-for="job in getJobPostingList"
        :key="`job-item-${job.id}`"
        :job-object="job"
      ></JobItem>
    </div>
  </main>

  <empty-container
    v-else
    title="No Job posting Available"
    description="We currently have no job openings, but we welcome you to share your details for future opportunities. We're eager to bring onboard individuals who align with our culture and have the skills for our projects. Please submit your resume and cover letter here."
    :is-image="false"
    btn-text="Submit Resume"
    :click-props="redirectMailTo"
  />

  </div>
</template>

<script>
import JobItem from "@/components/JobPosting/JobItem";
import EmptyContainer from "@/components/EmptyContainer";

export default {
  components: {
    JobItem,
    EmptyContainer
  },
  data() {
    return {};
  },
  computed: {
    getJobPostingList() {
      return this.$store.getters.getJobPostingList;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  created() {
    this.$store.dispatch("fetchJobPostingAPI");
  },
  methods: {
    redirectMailTo() {
      const subject = "I'm interested in applying to Countable!"
      const mailtoUrl = `mailto:operations@countable.ca?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoUrl;
    }
  }
};
</script>

<style lang="scss"></style>
